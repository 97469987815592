import { useCallback, useMemo, useState } from 'react';
import { Button } from '@material-ui/core';
import { getDynamicAVDevices } from 'circulator/components/CreateProcedureButton/getDynamicAVDevices';
import { ProcedureDialog } from 'circulator/components/Procedure/ProcedureDialog';
import { useGetDevice } from 'circulator/hooks/device/useGetDevice';
import { useMutateProcedure } from 'circulator/hooks/procedure/useMutateProcedure';
import Procedure from 'common/models/Procedure';
import { GoIcon } from 'common/utils/Icons';

export const CreateProcedureButton = () => {
    const [open, setOpen] = useState(false);
    const handleSetOpen = () => setOpen(true);

    const { device } = useGetDevice();
    const procedureMutation = useMutateProcedure();

    const handleCancel = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    const handleStart = useCallback(
        async procedure => {
            if (procedure != null && device != null) {
                try {
                    const dynamicAVDevices = await getDynamicAVDevices();
                    procedure.connectedDevices = dynamicAVDevices;
                    await procedureMutation.start.mutateAsync(procedure);
                    // we don't close this form
                    // because there is a delay between when this closes and
                    // when react query loads the procedure, which briefly
                    // shows the user the start button again
                    //setOpen(false);
                } catch (error) {
                    throw error;
                }
            }
        },
        [device, procedureMutation]
    );

    const procedure = useMemo(() => new Procedure({ device, isActive: true, notify: device.facility?.notifyUsersDefault }), [device]);
    const procedureForm = useMemo(
        () => (
            <ProcedureDialog
                procedure={procedure}
                open={open}
                onCancel={handleCancel}
                onStart={handleStart}
                error={procedureMutation.isErrorWhenStarting}
            />
        ),
        [procedure, open, handleCancel, handleStart, procedureMutation.isErrorWhenStarting]
    );
    return (
        <>
            <Button variant="contained" disabled={procedureMutation.isUpdating} onClick={handleSetOpen} color="primary" endIcon={<GoIcon />}>
                Start Procedure
            </Button>
            {procedureForm}
        </>
    );
};
