import { FC } from 'react';
import { Box, ButtonProps, CircularProgress, IconButton, makeStyles } from '@material-ui/core';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';

interface CapturePhotoButtonProps extends ButtonProps {
    showCapturing: boolean;
    onCapture: () => void;
}

const useStyles = makeStyles({
    container: {
        bottom: 0,
        right: 0,
        position: 'absolute',
        zIndex: 50,
    },
    button: {
        fontSize: '2rem !important',
        width: '60px !important',
        height: '60px !important',
        color: 'white !important',
    },
    spinner: {
        position: 'absolute',
        bottom: 20,
        right: 20,
        zIndex: 50,
    },
});

export const CapturePhotoButton: FC<CapturePhotoButtonProps> = ({ showCapturing, onCapture }) => {
    const classes = useStyles();

    if (showCapturing) {
        return <CircularProgress className={`${classes.spinner}`} thickness={5} color="primary" />;
    }

    return (
        <Box className={classes.container} onClick={onCapture}>
            <Box>
                <IconButton color="primary">
                    <AddAPhotoIcon className={`${classes.button}`} fontSize="large" />
                </IconButton>
            </Box>
        </Box>
    );
};
